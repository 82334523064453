import categoryCard from "./components/category-card/index.vue";
import {mapActions, mapGetters} from "vuex";
import VueHead from 'vue-head';
import Vue from "vue";

Vue.use(VueHead)

export default {
  name: "category-all",
  components: {
    categoryCard,
  },
  data() {
    return {
      params: {
        title: '',
        description: '',
        keywords: '',
        image: '',
      }
    }
  },
  head: {
    title: function () {
      return {
        inner: this.params.title !== '' ? this.params.title : '',
        separator: ' ',
      }
    },
    meta: function () {
      return [
        {n: 'keywords', content: this.params.keywords, id: 'keywords'},
        {p: 'og:title', content: this.params.title, id: 'og:title'},
        {p: 'og:description', content: this.params.description, id: 'og:description'},
        {n: 'title', content: this.params.title , id: 'title'},
        {n: 'description', content: this.params.description , id: 'description'},
        {p: 'og:image', content: this.params.image, id: 'og:image'},
      ]
    },
    link: function () {
      let route = this.$route.path
      if (route.slice(1, 4) === 'ru/' || route.slice(1, 4) === 'en/'){
        route = route.slice(3)
      }
      return [
        { rel: 'canonical', href: window.location.origin + this.$route.path, id: 'canonical' },
        {rel: 'alternate', hreflang: 'ru', href: window.location.origin + '/ru' + route, id: 'ru'},
        {rel: 'alternate', hreflang: 'en', href: window.location.origin+ '/en' + route, id: 'en'},
        {rel: 'alternate', hreflang: 'x-default', href: window.location.origin + route, id: 'x-default'},
      ]
    }
  },
  computed: {
    ...mapGetters({
      menuCatalog: `setting/menu`,
      page: `pages/page`
    })
  },
  created() {
    this.fetchAllCategory()
    this.fetchPageAllCategory('catalog')
  },
  watch: {
    page: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.setMeta()
        }
      }
    }
  },
  methods: {
    ...mapActions({
      fetchAllCategory: `setting/GET_CATEGORIES_MENU`,
      fetchPageAllCategory: `pages/GET_PAGE`
    }),
    setMeta() {
      this.params.title = this.page.meta.meta_title !== '' ? this.page.meta.meta_title : 'ICHIBAN STORE',
          this.params.description = this.page.meta.meta_description !== '' ? this.page.meta.meta_description : 'ICHIBAN STORE ICHIBAN STORE ICHIBAN STORE ICHIBAN STORE',
          this.params.keywords = this.page.meta.meta_keywords,
          this.params.image = this.page.image !== '' ? this.page.image : 'https://ichibanstore.jp/logo.png'
      this.$emit('updateHead')
    },
  }
}